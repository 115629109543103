<template>
  <div class="controllerJieshao">
    <div class="controller-modules-header left">病例介绍</div>
    <div class="dsc" v-html="caseInfo.desc"></div>
    <!-- <div class="step-header">学习目标</div>
    <div class="dsc">1/在诱导前如何识别血容量不足，为什么这些很重要？</div>
    <div class="step-header">麻醉注意事项</div>
    <div class="dsc">1/在诱导前如何识别血容量不足，为什么这些很重要？</div>
    <div class="step-header">建议管理</div>
    <div class="dsc">1/在诱导前如何识别血容量不足，为什么这些很重要？</div> -->
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["caseInfo"]),
  },
};
</script>
<style lang="scss">
@import "../index.scss";
</style>